import { combineReducers } from "redux";
import user from "./user";
import activeCurrency from "./currency";
import timezone from "./timezone";
import selectedTimezone from "./selectedTimezone";
import accountTagList from "./accounts";
import recentSearchList from "./recentSearch";
import theme from "./theme";

export default combineReducers({
  user,
  activeCurrency,
  timezone,
  accountTagList,
  recentSearchList,
  theme,
  selectedTimezone,
});
