import React, { useEffect, useState } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { NavLink, useHistory } from "react-router-dom";
import "../../assets/styles/custom.css";
import SearchData from "../../services/search";
import Utility from "../../utility";
import Popover from "./popover";
import ChangePassword from "./changePassword";
import { sessionManager } from "../../managers/sessionManager";
import { Row } from "simple-flexbox";
import { eventConstants, recentSearchTypeConstants } from "../../constants";
import { useDispatch } from "react-redux";
import TokenPopover from "./tokenPopover";
import SearchBox from "../../common/components/internalSearchBar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const drawerWidth = 240;
const Cut = styled.div`
  padding-right: 5px;
  padding-top: 15px;

  display: flex;
  align-content: flex-end;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "19px",
  },
  appBar: {
    paddingRight: "0px !important",
    position: "unset !important",
    backgroundColor: "#2149b9",
    height: "4.675rem",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarDark: {
    position: "unset !important",
    backgroundColor: "#132a69",
    height: "4.675rem",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  "@media (min-width: 0px) and (max-width:767px)": {
    appBar: {
      height: "10.8rem !important",
      padding: "15px",
    },
    appBarDark: {
      height: "10.8rem !important",
      padding: "15px",
    },
    drawerHeader: {
      padding: "0 !important",
    },
  },
  "@media (min-width: 767px) and (max-width:1250px)": {
    appBar: {
      backgroundColor: "#2149b9",
      height: "134px !important",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarDark: {
      height: "134px !important",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflow: "hidden",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#102e84",
    overflow: "hidden",
  },
  drawerHeader: {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    marginTop: "-12px",
  },
  searchIcon: {
    marginTop: "2px",
    width: 18,
    height: 18,
    marginRight: 3,
  },
  searchImageinputContainer: {
    display: "flex",
  },
  "@media (min-width: 0px) and (max-width: 767px)": {
    searchIcon: {
      width: 15,
      height: 15,
    },
    popover: {
      marginRight: "-15px",
    },
    firstContainer: {
      marginTop: "10px",
    },
    drawerHeader: {
      marginTop: "-10px",
    },
  },

  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  "@media (min-width: 640px) ": {
    list: {
      width: "21.25rem",
      backgroundColor: "#102e84",
      height: "100%",
    },
    listDark: {
      width: "21.25rem",
      backgroundColor: "#283966",
      height: "100%",
    },
  },

  "@media (min-width: 0px) and (max-width: 767px)": {
    list: {
      width: "153px",
      backgroundColor: "#102e84",
      height: "100%",
    },
    listDark: {
      width: "21.25rem",
      backgroundColor: "#283966",
      height: "100%",
    },
  },
  fullList: {
    width: "auto",
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { addr } = useParams();
  const currentTheme = props.theme;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [opencontracts, setOpencontracts] = useState(false);
  const ref = React.useRef(null);
  const SelectOptRef = React.useRef(null);
  const SearchDataRef = React.useRef(null);

  const [openPasswordBox, setOpenPasswordBox] = React.useState(false);
  const [isTokenPopver, setTokenPopover] = React.useState(false);

  const openChangePassword = () => {
    setOpenPasswordBox(!openPasswordBox);
  };
  const handleTokenPopover = () => {
    setTokenPopover(true);
  };
  const closeTokenPopover = () => {
    setTokenPopover(false);
  };
  const isloggedIn = sessionManager.getDataFromCookies("isLoggedIn");

  useEffect(() => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let address = addr;
    if (address && address.startsWith("0x")) {
      address = address.replaceAll("0x", "xdc");
      if (format.test(address)) {
        console.log(address);
      }
    }
  }, [addr]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const lists = (anchor) => (
    <div
      className={
        props.theme === "dark"
          ? clsx(classes.listDark, {
              [classes.fullList]: anchor === "top" || anchor === "bottom",
            })
          : clsx(classes.list, {
              [classes.fullList]: anchor === "top" || anchor === "bottom",
            })
      }
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.firstContainer}>
        <p
          className={
            props.theme === "dark"
              ? "inside-side-box-browse fc-white"
              : "inside-side-box-browse"
          }
        >
          Browse
        </p>
        <div className={classes.drawerHeader}>
          <IconButton
            style={{ color: "white" }}
            onClick={toggleDrawer(anchor, false)}
          >
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </div>
      </div>

      <List className={props.theme === "dark" ? "side-box-dark" : "side-box"}>
        <ul className="inside-side-box">
          <a className="account_details_button" href="/account-details">
            <div className="xinfin_account_button">Accounts</div>
          </a>
          <hr className="myhr" />
        </ul>

        <ul className="inside-side-box">
          <p
            className="xinfin_api_button"
            onClick={() => setOpencontracts(true)}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Contracts{" "}
            <span className="side-arrow-contract-tab">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
          </p>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a className="sidebar-links" href="/blockchain-identity">
            <div className="xinfin_account_button">Blockchain Identity</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="https://origin.xdc.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">XDC Origin</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="https://chrome.google.com/webstore/detail/xdcpay/bocpokimicclpaiekenaeelehdjllofo?hl=en-US"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">XDCPay</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="https://stats.xdc.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">XDC Network Stats</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="http://observatoryapi.xdc.org:3008/swagger-docs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">API Document</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="https://github.com/xdcfoundation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">XDC Github</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="https://xdcroadmap.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">XDC Roadmap</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Network-list-nav">
          <a
            className="sidebar-links"
            href="https://medium.com/xdc-foundation-communications"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="xinfin_account_button">About XDC</div>
          </a>
          <hr className="myhr" />
        </ul>

        {/* <ul className="inside-side-box">
          <p
            className="xinfin_api_button"
            onClick={() => setOpen(true)}
            style={{ cursor: "pointer" }}
          >
            Tools{" "}
            <span className="right-arrow-side-bar">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
          </p>
          <hr className="myhr" />
        </ul>
        <ul className="inside-side-box">
          <p className="xinfin_api_button" style={{ cursor: "pointer" }}>
            XDC APIs
          </p>
          <hr className="myhr" />
        </ul>
        <ul className="inside-side-box">
          <p className="xinfin_api_button" style={{ cursor: "pointer" }}>
            Nodes
          </p>
          <hr className="myhr" />
        </ul> */}
      </List>
    </div>
  );
  const handleClose = () => {
    setOpen(false);
  };

  const list = ["Accounts", "Contract", "Tools", "XDC Apis", "Nodes", "Tokens"];
  const [filter, setFilter] = useState("");
  const childToggle = (subanchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpencontracts(false);

    setState({ ...state, [subanchor]: open });
  };
  const contracts = (subanchor) => (
    <div
      style={{ overflow: "revert" }}
      className={
        props.theme === "dark"
          ? clsx(classes.listDark, {
              [classes.fullList]: subanchor === "top" || subanchor === "bottom",
            })
          : clsx(classes.list, {
              [classes.fullList]: subanchor === "top" || subanchor === "bottom",
            })
      }
      role="presentation"
      onKeyDown={() => setOpencontracts(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "40px 10px 15px 20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row style={{ gap: "5px", alignItems: "center" }}>
          <i
            class="fa fa-angle-left"
            aria-hidden="true"
            onClick={() => setOpencontracts(false)}
            style={{ color: "white", fontSize: 14, cursor: "pointer" }}
          ></i>
          <div
            style={{
              color: "white",
              fontSize: 13,
              cursor: "pointer",
            }}
            onClick={() => setOpencontracts(false)}
          >
            Contract
          </div>
        </Row>
        <div>
          <IconButton
            style={{ color: "white" }}
            onClick={childToggle(subanchor, false)}
          >
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </div>
      </div>

      <List className={props.theme === "dark" ? "side-box-dark" : "side-box"}>
        <ul className="Live-Network-list">
          <a
            style={{
              fontSize: 13,
              color: "white",
              listStyle: "none",
              textDecoration: "none",
            }}
            href="/contracts"
          >
            <div className="xinfin_api_button">Contracts</div>
          </a>
          <hr className="myhr4" />
        </ul>
        <ul className="Live-Network-list">
          <a
            style={{
              fontSize: 13,
              color: "white",
              listStyle: "none",
              textDecoration: "none",
            }}
            href={"/verify-contracts"}
          >
            <div className="xinfin_api_button">Verify Contracts</div>
          </a>
          <hr className="myhr4" />
        </ul>
      </List>
    </div>
  );
  const childToolsToggle = (subanchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(false);
    setState({ ...state, [subanchor]: open });
  };
  const items = (subanchor) => (
    <div
      style={{ overflow: "revert" }}
      className={`scrollbar ${
        props.theme === "dark"
          ? clsx(classes.listDark, {
              [classes.fullList]: subanchor === "top" || subanchor === "bottom",
            })
          : clsx(classes.list, {
              [classes.fullList]: subanchor === "top" || subanchor === "bottom",
            })
      }`}
      role="presentation"
      onKeyDown={() => setOpen(false)}
    >
      <Row
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "0 20px 0 20px", marginTop: "20px" }}
      >
        <Row alignItems="center">
          <i
            class="fa fa-angle-left"
            aria-hidden="true"
            onClick={() => setOpen(false)}
            style={{ color: "white", fontSize: 15, cursor: "pointer" }}
          ></i>
          <div
            style={{
              color: "white",
              fontSize: 13,
              marginLeft: "8px",
              cursor: "pointer",
            }}
          >
            Tools
          </div>
        </Row>
        <div>
          <IconButton
            style={{ color: "white" }}
            onClick={childToolsToggle(subanchor, false)}
          >
            {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </div>
      </Row>

      {/* onClick={() => setOpen(false)} */}
      <List className="side-box">
        <ul className="Live-Network">
          <p>Live Network</p>
        </ul>
        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://wallet.xinfin.network/#/">
            <div className="xinfin_account_button">Web Wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://play.google.com/store/apps/details?id=com.xdcwallet"
          >
            <div className="xinfin_account_button">Android wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://xinfin.network/#explorer">
            <div className="xinfin_account_button">Block Observatory</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://apidocs.xinfin.network/docs/#xinfin-apis"
          >
            <div className="xinfin_account_button">XDC APIs</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul style={{ whiteSpace: "nowrap" }} className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://xinfin.network/#masternode"
          >
            <div className="xinfin_account_button">
              Become a MasterNode/Validator
            </div>
          </a>
          <hr className="myhr" />
        </ul>
      </List>
      <br />
      <List className="side-box">
        <ul className="Live-Network">
          <p>Sand Box/Testnet</p>
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://apothem.network/#getTestXDC"
          >
            <div className="xinfin_account_button">Faucet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://wallet.apothem.network/#/">
            <div className="xinfin_account_button">Web wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://explorer.apothem.network/home"
          >
            <div className="xinfin_account_button">Block Observatory</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://apidocs.xinfin.network/docs/#xinfin-apis"
          >
            <div className="xinfin_account_button">XDC APIs</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul style={{ whiteSpace: "nowrap" }} className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://apothem.network/#masternode"
          >
            <div className="xinfin_account_button">
              Become a MasterNode/Validator
            </div>
          </a>
          <hr className="myhr" />
        </ul>
      </List>
      <br />
      <List className="side-box">
        <ul className="Live-Network">
          <p>Supported Wallet</p>
        </ul>
        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://guarda.com/">
            <div className="xinfin_account_button">Guarda Wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://dcentwallet.com/MobileApp">
            <div className="xinfin_account_button">D'CENT Wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://dcentwallet.com/products/BiometricWallet"
          >
            <div className="xinfin_account_button">D'CENT Hardware Wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://freewallet.org/#wallets">
            <div className="xinfin_account_button">Freewallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul style={{ whiteSpace: "nowrap" }} className="Live-Network-list">
          <a className="sidebar-links" href="https://xcelpay.io/">
            <div className="xinfin_account_button">XcelPay Wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul style={{ whiteSpace: "nowrap" }} className="Live-Network-list">
          <a className="sidebar-links" href="https://bitfi.com/">
            <div className="xinfin_account_button">Bitfi Hardware Wallet</div>
          </a>
          <hr className="myhr" />
        </ul>
      </List>
      <br />
      <List className="side-box">
        <ul className="Live-Network">
          <p>More</p>
        </ul>

        <ul className="Live-Network-list">
          <a className="sidebar-links" href="https://remix.xinfin.network/">
            <div className="xinfin_account_button">XDC Remix</div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://xinfin.org/setup-masternode"
          >
            <div className="xinfin_account_button">
              One-Click Node Installer
            </div>
          </a>
          <hr className="myhr" />
        </ul>
        <ul className="Live-Network-list">
          <a
            className="sidebar-links"
            href="https://explorer.xinfin.network/home"
          >
            <div className="xinfin_account_button">Explore dApps</div>
          </a>
          <hr className="myhr" />
        </ul>
      </List>
    </div>
  );

  // ..................
  const NavigationButton1 = styled.a`
    text-decoration: none;
    padding: 5px 20px;
    border-bottom: ${(props) =>
      props.active ? "0.15rem solid #ffffff !important" : ""};
    padding-bottom: 3px;
    font-size: 0.938rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    color: #ffffff;
    list-style: none;
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 0.875rem;
    }
  `;
  const NavigationButton = styled.div`
    display: flex;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 20px;
    border-bottom: ${(props) =>
      props.active ? "0.15rem solid #ffffff !important" : ""};
    padding-bottom: 3px;
    font-size: 0.938rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;

    color: #ffffff;
    list-style: none;
    @media (min-width: 0px) and (max-width: 767px) {
      font-size: 0.875rem;
    }
  `;

  const MobileNavigationContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-flow: row;
    margin: 15px 0 0 0;
    @media (min-width: 768px) {
      display: none;
    }
  `;

  const DeskTopView = styled.div`
    @media (min-width: 0px) and (max-width: 767px) {
      display: none;
    }
    @media (min-width: 768px) {
      display: visible;
    }
  `;
  const MobileView = styled.div`
    @media (min-width: 0px) and (max-width: 766px) {
      display: visible;
    }
    @media (min-width: 767px) {
      display: none;
    }
  `;

  const SearchContainer = styled.div`
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
    position: absolute;
    padding: 7px 8px 9px;
    border-radius: 6px;
    border: solid 1px #e3e7eb;
    margin: auto;
    background: white;
    margin: 21.8px auto auto auto;
  `;

  const MobileSearchParent = styled.div`
    display: flex;
    width: 100%;
    height: 38px;
    justify-content: center;
    flex-flow: row;
    @media (min-width: 1250px) {
      display: none;
    }
  `;

  const MobileToolBar = styled.div`
    margin: 2px 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px 0 0 10px;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      padding: 0 !important;
      margin: 0 !important;
    }
  `;

  const TabSearchBox = styled.div`
    position: absolute;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
    padding: 9px 12px;
    // margin-right: 0.625rem;
    margin-top: 10px;
    border-radius: 7px;
    background-color: #ffffff;
  `;

  const TabSearchBoxParent = styled.div`
    display: flex;
    height: 2.4375rem;
    justify-content: center;
    flex-flow: row;
    @media (min-width: 0px) and (max-width: 767px) {
      display: none;
    }
    @media (min-width: 1250px) {
      display: none;
    }
  `;

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <DeskTopView> */}

      <AppBar
        // className="mob-height"
        elevation={0}
        className={
          props.theme === "dark"
            ? clsx(classes.appBarDark)
            : clsx(classes.appBar)
        }
      >
        <MobileToolBar className={clsx(classes.toolBar)}>
          <div className="tab-search">
            {/* <Typography className="Header"> */}
            <Row className="Header">
              <Row alignItems="center">
                <a className="logo_tokensearch" href={"/"}>
                  <img
                    className="Shape"
                    src={"/images/XDC-Icon-Logo.svg"}
                  ></img>
                </a>
                <a className="XDC" href="/">
                  {" "}
                  XDC{" "}
                </a>
              </Row>

              <DeskTopView>
                <div>
                  <NavLink
                    exact
                    activeClassName="active-t"
                    to={"/"}
                    className="Network-explorer"
                  >
                    XDC Observatory
                  </NavLink>

                  {/* <p className="Network-explorer" active id="Network-explorer">Network</p> */}
                  <TokenPopover
                    open={isTokenPopver}
                    handleClose={closeTokenPopover}
                  />
                </div>
                <div>
                  <div
                    activeClassName="activet"
                    // to={"/tokens"}
                    className="Token cursorpointer display-flex-i"
                    onClick={handleTokenPopover}
                  >
                    <div>Tokens</div>
                    <img
                      className="token-dropdown-icon"
                      src="/images/Dropdown.svg"
                    ></img>
                  </div>

                  <a href="/">
                    <p className="Network-explorer" id="Network-explorer">
                      XDC Observatory
                    </p>
                  </a>
                </div>
                <div>
                  <div>
                    <div
                      onClick={handleTokenPopover}
                      className="Token cursor-pointer display-flex-i"
                      id="Token"
                    >
                      <div>Tokens</div>
                      <img
                        className="token-dropdown-icon"
                        src="/images/Dropdown.svg"
                      ></img>
                    </div>
                  </div>
                </div>
              </DeskTopView>

              {/* </div> */}
            </Row>
            <DeskTopView>
              <div className="parent-Center-box">
                <div
                  className={
                    props.theme === "dark"
                      ? "centerbox-td-dark"
                      : "centerbox-td"
                  }
                >
                  <SearchBox
                    classes={classes}
                    filter={filter}
                    SearchDataRef={SearchDataRef}
                    SelectOptRef={SelectOptRef}
                    list={list}
                    currentTheme={currentTheme}
                  />
                </div>
              </div>
            </DeskTopView>
            {/* <div className="display-none-desktop"> */}

            {/* </div> */}
          </div>

          {LoginComponent({
            toggleDrawer,
            isloggedIn,
            openChangePassword,
            classes,
            childToggle,
            opencontracts,
            state,
            lists,
            items,
            contracts,
            openPasswordBox,
            open,
            currentTheme,
          })}

          {/* <div className="display-none-mobile">
            {LoginComponent({
              toggleDrawer,
              isloggedIn,
              openChangePassword,
              classes,
              opencontracts,
              state,
              lists,
              items,
              contracts,
              openPasswordBox,
              open,
            })}
          </div> */}
        </MobileToolBar>
        <MobileView>
          <MobileNavigationContainer>
            <NavigationButton1
              active={window.location.pathname == "/"}
              href="/"
            >
              XDC Observatory
            </NavigationButton1>
            <NavigationButton
              active={window.location.pathname.includes("token")}
              onClick={handleTokenPopover}
            >
              <div>Tokens</div>
              <img
                className="token-dropdown-icon"
                src="/images/Dropdown.svg"
              ></img>
            </NavigationButton>
          </MobileNavigationContainer>
          <MobileSearchParent>
            <SearchContainer>
              <SearchBox
                classes={classes}
                filter={filter}
                SearchDataRef={SearchDataRef}
                SelectOptRef={SelectOptRef}
                list={list}
                shortenValue={true}
                currentTheme={currentTheme}
              />
            </SearchContainer>
          </MobileSearchParent>
        </MobileView>
        <TabSearchBoxParent>
          <TabSearchBox>
            <SearchBox
              classes={classes}
              filter={filter}
              SearchDataRef={SearchDataRef}
              SelectOptRef={SelectOptRef}
              shortenValue={true}
              list={list}
              currentTheme={currentTheme}
            />
          </TabSearchBox>
        </TabSearchBoxParent>
      </AppBar>
      {/* </DeskTopView> */}
    </div>
  );
}

const LoginComponent = ({
  toggleDrawer,
  childToggle,
  isloggedIn,
  openChangePassword,
  classes,
  opencontracts,
  state,
  lists,
  items,
  contracts,
  openPasswordBox,
  open,
  currentTheme,
}) => {
  return (
    <Row className={classes.popover} alignItems="center">
      {openPasswordBox && (
        <ChangePassword
          openChangePassword={openChangePassword}
          theme={currentTheme}
        />
      )}
      {/* <Popover theme={currentTheme} openChangePassword={openChangePassword} /> */}

      <div className="rigt-line" key={"right"}>
        <IconButton
          className="hamburger-icon"
          color="inherit"
          aria-label="open drawer"
          // edge="end"
          onClick={toggleDrawer("right", true)}
        >
          <img
            className={isloggedIn ? "Shape2-internal1" : "menu-sidebar"}
            src={"/images/Menu.svg"}
          ></img>
        </IconButton>

        <Drawer
          className={classes.drawer}
          anchor={"right"}
          open={state["right"]}
          onEscapeKeyDown={toggleDrawer("right", false)}
          onBackdropClick={toggleDrawer("right", false)}
        >
          {lists("right")}
        </Drawer>
        <Drawer
          className={classes.drawer}
          anchor={"right"}
          onEscapeKeyDown={toggleDrawer("right", false)}
          onBackdropClick={toggleDrawer("right", false)}
          open={open}
        >
          {items("right")}
        </Drawer>
        <Drawer
          className={classes.drawer}
          anchor={"right"}
          open={opencontracts}
          onEscapeKeyDown={childToggle("right", false)}
          onBackdropClick={childToggle("right", false)}
        >
          {contracts("right")}
        </Drawer>
      </div>
    </Row>
  );
};
