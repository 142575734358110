import React, { useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../utility";
import { eventConstants } from "../../constants";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 0px 16px;
  width: 100%;
  position: relative;
  background-color: #fff;
  margin-top: 16px;
  border-top: solid 1px #e3e7eb;
  max-height: 227px;
  overflow-y: auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const RedirectIcon = styled.img`
  width: 8px;
  height: 9px;
  cursor: pointer;
`;

const RemoveIcon = styled.img`
  width: 8px;
  height: 9px;
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const ActiveRedirectIcon = styled.img`
  width: 8px;
  height: 9px;
  cursor: pointer;
  display: none;
`;

const ItemContainer = styled(Column)`
  height: 58px;
  padding: 8px 9px 8px 14px;
  border-radius: 8px;
  border: solid 1px #c9d7ff;
  background-color: transparent;
  cursor: pointer;
  ${({ theme }) =>
    theme === "dark" &&
    `
     border: solid 1px #3552a5;
  `}

  &:hover {
    background-color: #fbfcff;
    ${({ theme }) =>
      theme === "dark" &&
      `
     background-color: #192a59;
  `}
  }

  &:hover ${RedirectIcon} {
    display: none;
  }

  &:hover ${ActiveRedirectIcon} {
    display: block;
  }

  @media (min-width: 0px) and (max-width: 767px) {
    width: 100%;
  }
`;

const SearchTypeIcon = styled.img`
  //width: 18px;
  height: 26px;
  margin-top: -3px;
`;

const TextContainer = styled(Column)`
  width: 100%;
  position: relative;
`;

const SearchValue = styled.span`
  height: 17px;
  margin: 0 8px 5px 11px;
  font-family: Inter;
  font-size: 14px;
  //letter-spacing: 0.54px;
  color: #2149b9;
  ${({ theme }) =>
    theme === "dark" &&
    `
    color: #4874f4;
  `}
`;

const ResultValue = styled.span`
  margin: 0 0 4px 11px;
  font-family: Inter-Regular_;
  font-size: 12px;
  font-weight: 600;
  //letter-spacing: 0.46px;
  color: #2149b9;
  ${({ theme }) =>
    theme === "dark" &&
    `
    color: #4874f4;
  `}
`;

const recentSearchIconConstants = {
  TRANSACTION: "/images/transaction-recent-search.svg",
  TOKEN: "/images/contract-recent-search.svg",
  WALLET: "/images/wallet-recent-search.svg",
};

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function getAmount(amount) {
  let amt = Utility.decimalDivison(amount, 6);
  let amt1 = amt?.toString()?.split(".")[0];
  let amt2 = amt?.toString()?.split(".")[1];
  return `${amt2 ? `${amt1}.${amt2}` : `${amt1}`} XDC`;
}

function shorten(b, amountL = 10, amountR = 3, stars = 3) {
  if (!b || b.length < 10) return b || "";
  return `${b?.slice(0, amountL)}${".".repeat(stars)}${b?.slice(
    b.length - 3,
    b.length
  )}`;
}

export default function SearchPopover({
  searchString,
  theme,
  mouseHovering,
  shortenValue = false,
}) {
  const dataList = useSelector((state) => state.recentSearchList);
  const dispatch = useDispatch();

  const finalList = useMemo(
    () => dataList.filter((e) => e.searchValue.includes(searchString)),
    [dataList, searchString]
  );

  const removeSearchSuggestion = (searchValue) => {
    dispatch({
      type: eventConstants.REMOVE_SEARCH_LIST_ITEM,
      payload: {
        searchValue: searchValue,
      },
    });
  };

  if (finalList.length === 0) return <></>;

  return (
    <SearchResultWrapper
      onMouseOver={() => mouseHovering(true)}
      onMouseOut={() => mouseHovering(false)}
    >
      {finalList?.length > 0 &&
        finalList.map((obj, index) => (
          <ItemContainer theme={theme} key={index}>
            <Row style={{ alignItems: "center" }}>
              <SearchTypeIcon
                src={recentSearchIconConstants[obj.type]}
                alt={"icon"}
              />
              <TextContainer
                onClick={() => {
                  window.location.href = obj.redirectUrl;
                }}
              >
                <InfoWrapper>
                  <SearchValue theme={theme}>
                    {shortenValue ? shorten(obj.searchValue) : obj.searchValue}
                  </SearchValue>
                  <RemoveIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSearchSuggestion(obj.searchValue);
                    }}
                    className="cross-icon"
                    src={"/images/XDC-Cross.svg"}
                    alt="remove search suggestion"
                  />
                </InfoWrapper>
                <InfoWrapper>
                  <ResultValue theme={theme}>
                    {getAmount(obj.result)}
                  </ResultValue>
                  <RedirectIcon src={"/images/up-arrow-recent-search.svg"} />
                  <ActiveRedirectIcon
                    src={"/images/up-arrow-active-recent-search.svg"}
                  />
                </InfoWrapper>
              </TextContainer>
            </Row>
          </ItemContainer>
        ))}
    </SearchResultWrapper>
  );
}
