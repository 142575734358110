export const httpConstants = {
  METHOD_TYPE: {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
  DEVICE_TYPE: {
    WEB: "web",
  },
  API_END_POINT: {
    GET_TOKEN_OVERVIEW: "/get-token-overview",
    GET_HISTORY_PRICE: "/get-history-price",
    GET_TOKEN_TRANSFER_COUNT: "/get-token-transfer-count",
    GET_TOKEN_BALANCE: "/get-token-balance",
    GET_TOKEN_LIST: "/getListOfTokens",
    GET_TOKEN_INFO: "/token-info",
    GET_ADDRESS_ANALYTICS: "/get-address-analytics",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    GET_USERS_LIST: "/get-user-list",
    INVITE_ADMIN_USER: "/invite-admin-user",
    UPDATE_USER: "/update-user",
    GET_USER: "/user",
    GET_USER_INFO: "/user-info",
    GET_ACCOUNT_TAGS: "/getAccountTags",
    INVITE_USER: "/invite-user",
    ACCEPT_USER: "/accept-user",
    REJECT_USER: "/reject-user",
    EXPORT_USER: "/export-user",
    SUBSCRIPTION_PLANS: "/subscription-plan",
    FEEDS: "/feeds",
    GEY_USERS_BY_ROLE: "/get-users-by-role",

    POST: "/post",

    REACT_APP_GET_TOTAL_ACCOUNTS: "/getTotalAccounts",
    REACT_APP_GET_ACCOUNT_LIST: "/accounts-list",
    REACT_APP_GET_SOME_DAYS_ACCOUNTS: "/getSomeDaysAccounts/14",
    REACT_APP_GET_LATEST_ACCOUNTS: "/getLatestAccounts",
    REACT_APP_GET_TOKEN_LIST: "/getListOfTokens",
    REACT_APP_GET_TOTAL_TOKEN: "/getTotalTokens",
    REACT_APP_GET_TOKEN_SEARCH: "/getTokenUsingTokenNameAndAddress",
    REACT_APP_GET_ADDRESS_DETAILS: "/getAccountDetailsUsingAddress/",
    REACT_APP_GET_CONTRACT_LIST: "/getListOfContracts",
    REACT_APP_GET_TOTAL_CONTRACT_LIST: "/getTotalContracts",
    REACT_APP_GET_CONTRACT_SEARCH: "/getContractSearch",
    REACT_APP_GET_TRANSACTION_SEARCH: "/getSearchAccountTransactionUsingHash/",
    REACT_APP_GET_LIST_OF_HOLDERS_FOR_TOKEN: "/getListOfHoldersForToken/",
    REACT_APP_GET_CONTRACT_DETAILS: "/getContractDetailsUsingAddress/",
    REACT_APP_GET_CONTRACT_DETAIL_USING_ADDRESS:
      "/getContractDetailsUsingAddress/",
    REACT_APP_GET_HOLDER_DETAIL_USING_ADDRESS_FOR_TOKEN:
      "/getHolderDetailsUsingAddress?address:",
    REACT_APP_GET_SOME_DAYS_HOLDERS: "/getSomeDaysHolders/14/",
    REACT_APP_USER_SERVICE_URL_AUTHENTICATION: "/auth-srv/",
    REACT_APP_USER_SERVICE_URL_GLOBAL_ID_AUTHENTICATION: "/global-id/",
    REACT_APP_WATCHLIST_TRANSACTION_SERVICE: "/watchlist-srv/",
    REACT_APP_NOTIFICATION_SERVICE: "/notifications/",
    REACT_APP_GET_REWARDS: "/getAccountRewards/",
    REACT_APP_GET_REWARD_COUNT_FOR_ADDRESS: "/getRewardCountForAddress/",
    REACT_APP_GET_REWARDS_FOR_EPOCH: "/getRewardsForEpoch/",
    REACT_APP_GET_REWARDS_COUNTS_FOR_EPOCH: "/getRewardsCountForEpoch/",
    REACT_APP_GET_INTERNAL_TRANSACTION_COUNT_FOR_ADDRESS:
      "/getInternalTransactionsCount/",
    REACT_APP_GET_INTERNAL_TRANSACTION_LIST_FOR_ADDRESS:
      "/getInternalTransactions/",
    REACT_APP_GET_INTERNAL_TRANSACTION_BY_HASH:
      "/getInternalTransactionsByHash/",
    REACT_APP_GET_EPOCH_NUMBER: "/epoch/",
    REACT_APP_GET_LIST_OF_TOKENS_FOR_ADDRESS: "/getListOfTokenForAddress/",
    REACT_APP_GET_TOKEN_HASH_DETAIL: "/getTokenHashDetail/",
    REACT_APP_GET_TOKEN_PRICE_USING_TIMESTAMP: "/gettokenPriceUsingTimestamp",

    REACT_APP_GET_COIN_PRICE_FOR_TRANSACTION: "/getCoinPriceForTransaction",
    REACT_APP_GET_NETWORK_DETAILS: "/getNetworkDetails",
    REACT_APP_GET_XRC721_TRANSACTION_LIST_BY_ACCOUNT:
      "/getListOfXRC721TransactionsByAccount/",

    REACT_APP_GET_XRC721_TRANSACTION_LIST_BY_TX:
      "/getListOfXRC721TransactionsByTxHash/",

    REACT_APP_GET_TOTAL_XRC721_TRANSACTIONS_BY_ACCOUNT:
      "/getTotalXRC721TransactionsByAccount/",
    REACT_APP_GET_ADDRESS_STATS: "/getAddressStats/",
    REACT_APP_GET_COIN_MARKET_TOTAL_SUPPLY: "/getCoinMarketTotalSupply",
    REACT_APP_GET_TRANSACTIONS_FOR_ADDRESS: "/getTransactionsForAddress/",
    REACT_APP_GET_FILTERED_TRANSACTIONS_FOR_ADDRESS:
      "/address-transaction-list/",
    REACT_APP_GET_FILTERS_FOR_ADDRESS_TXN: "/address-transaction-filters/",
    REACT_APP_GET_TRANSACTIONS_COUNT_FOR_ADDRESS:
      "/getTransactionsCountForAddress/",
    REACT_APP_GET_LIST_OF_TRANSFER_BY_CONTRACT:
      "/getListOfTransferTransactionsByContract/",
    REACT_APP_GET_LIST_OF_TRANSFER_FOR_ACCOUNT:
      "/getListOfTransferTransactionsByAccount/",
    REACT_APP_GET_COIN_MARKET_DETAIL_FOR_TRANSACTION:
      "/getCoinMarketDetailForTransaction",
    REACT_APP_GET_TRANSFER_TRANSACTION_DETAIL_USING_ADDRESS:
      "/getTransferTransactionDetailUsingHash",
    REACT_APP_GET_TRANSFER_TRANSACTION_XRC20: "/getXrc20transaction",
    REACT_APP_GET_MAXTPS_COUNTER: "/getMaxTPS",
    REACT_APP_POST_SEARCH_DATA: "/searchBlockchainData",
    REACT_APP_GET_TOKEN_MARKETCAP: "/getCoinMarketExchangeForToken",
    REACT_APP_GET_TOTAL_TRANSFER_BY_ACCOUNT:
      "/getTotalTransferTransactionByAccount/",
    REACT_APP_GET_TOTAL_TRANSFER_BY_CONTRACT:
      "/getTotalTransferTransactionByContract/",
    REACT_APP_GET_XRC20_TRANSACTIONS_LIST: "/getXRC20TransactionList",
    REACT_APP_GET_XRC20_TRANSACTIONS_LIST_COUNT: "/getXRC20TransactionCount",
    REACT_APP_GET_XRC721_TRANSACTIONS_LIST: "/getXRC721TransactionList",
    REACT_APP_GET_XRC721_TRANSACTIONS_LIST_COUNT: "/getXRC721TransactionCount",
    REACT_APP_GET_TRANSACTION_DETAILS: "/getTransactionDetails/",
    REACT_APP_GET_TPS_COUNTER: "/getCurrentTPS",
    REACT_APP_GET_LATEST_TRANSACTIONS: "/getLatestTransactions",
    REACT_APP_GET_SOME_DAYS_TRANSACTIONS: "/getSomeDaysTransactions/14",
    REACT_APP_GET_COIN_MARKET_CAP: "/getCoinMarketCap/",
    REACT_APP_GET_TOTAL_TRANSACTION: "/getTotalTransactions",

    REACT_APP_GET_TOTAL_BLOCKS: "/getTotalBlocks",
    REACT_APP_GET_LATEST_BLOCKS: "/getLatestBlocks",
    REACT_APP_GET_BLOCK_DETAIL: "/getBlockDetailsUsingBlockNumber/",
  },
};

export const userCookiesConstants = {
  FUNCTIONAL_COOKIES: "functionalCookies",
  PERFORMANCE_COOKIES: "performanceCookies",
  ANALYTICAL_COOKIES: "analyticalCookies",
};

export const cookiesConstants = {
  ACCOUNT_TAGS: "accountTags",
  USER: "USER",
  SESSION_TOKEN: "SESSION_TOKEN",
  USER_PERMISSION: "USER_PERMISSION",
  AUTH0_ACCESS_TOKEN: "AUTH0_ACCESS_TOKEN",
  USER_DETAIL: "USER_DETAIL",
  USER_SUB: "USER_SUB",
  USER_TAGGED_ADDRESS: "USER_TAGGED_ADDRESS",
  USER_TRASACTION_LABELS: "USER_TRASACTION_LABELS",
  USER_ADDRESS_WATCHLIST: "USER_ADDRESS_WATCHLIST",
  USER_COOKIES: "userCookies",
  USER_INFO: "userInfo",

  IS_LOGGEDIN: "isLoggedIn",
  USER_PICTURE: "USER_PICTURE",
  USER_ID: "USER_ID",
  EMAIL_ID: "EMAIL_ID",
  AUTH0_ID_TOKEN: "AUTH0_ID_TOKEN",
  JWT_TOKEN: "JWT_TOKEN",

  ACCESS_TOKEN: "ACCESS_TOKEN",
  ID_TOKEN: "ID_TOKEN",
  AUTHENTICATION_PROVIDER: "AUTHENTICATION_PROVIDER",
  OBSERVER_THEME: "OBSERVER_THEME",
  OBSERVER_CURRENCY: "OBSERVER_CURRENCY",
  OBSERVER_TIMEZONE: "OBSERVER_TIMEZONE",
};

export const eventConstants = {
  ADD_ACCOUNT_TAGS: "ADD_ACCOUNT_TAGS",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_LOADER: "SHOW_LOADER",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  ADD_TO_SEARCH_LIST: "ADD_TO_SEARCH_LIST",
  CLEAR_SEARCH_LIST: "CLEAR_SEARCH_LIST",
  REMOVE_SEARCH_LIST_ITEM: "REMOVE_SEARCH_LIST_ITEM",
  TOGGLE_THEME: "TOGGLE_THEME",
  ACTIVE_CURRENCY: "ACTIVE_CURRENCY",
  ACTIVE_TIMEZONE: "ACTIVE_TIMEZONE",
};

export const genericConstants = {
  INCORRECT_USERNAME_PASS: "Incorrect username or password",
  ENTER_REQUIRED_FIELD: "Please enter the required information",
  INVALID_EMAIL: "Invalid email",
  PASSWORD_DID_NOT_MATCH: "Both the passwords did not match",
  PASSWORD_UPDATE_SUCCESS: "Your Password has been updated.",
  RESET_PASSWORD_MAIL_SENT: "A reset password email has been sent.",
  PASSWORD_UPDATE_FAILED: "Failed to update your password",
  CANNOT_GET_NOTIFICATIONS: "No notifications",
  CANNOT_CLEAR_NOTIFICATIONS: "No notifications",
  WATCHLIST_DELETED: "WatchList deleted",
  CANNOT_DELETE_WATCHLIST: "Cannot delete watchlist",
  CANNOT_DELETE_TXN_PRIVATE_NOTE: "Cannot delete Txn private notes",
  TXN_PRIVATE_NOTE_DELETED: "Txn Private Note Deleted",
  CANNOT_DELETE_TAGGED_ADDRESS: "Cannot delete Tagged Addresses",
  TAGGED_ADDRESS_DELETED: "Tagged Address Deleted",
  SCM_REFERENCE: "scm",
  IMPORTED_TAG_LENGTH_ERROR:
    "Imported Name Tag should be maximum 15 characters.",
  WRONG_CURRENT_PASSWORD: "You have entered wrong current password",
  DESCRIPTION_LIMIT: "Description should be maximum 220 characters",
  TRANSACTION_LABEL_LIMIT:
    "Transaction label/note cannot be longer than 120 characters",
};

export const statusConstants = {
  PENDING: "PENDING",
  INACTIVE: "INACTIVE",
  INVITED: "INVITED",
  ACTIVE: "ACTIVE",
  PUBLISHED: "PUBLISHED",
  REJECTED: "REJECTED",
};

export const sidebarConstants = {
  USERS: "users",
};
export const reduxEvent = {
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
};

export const messages = {
  MARKET_CAP:
    "The total market value of a cryptocurrency's circulating supply. \nMarket Cap = Current Price x Circulating Supply.",
  CIRCULATING_SUPPLY:
    "The amount of coins that are circulating in the market and are tradeable by the public.",
  TOTAL_SUPPLY:
    "The amount of coins that have been already created, minus any coins that have been burned.",
  VOLUMEX24:
    "A measure of how much of a cryptocurrency was traded in the last 24 hours.",
  FDMP: "Fully Diluted Market Cap = Price x Total supply.",
  HASH: "Unique transaction identifier, also known as the Transaction ID.",
  PARENT_HASH:
    "Unique Parent transaction identifier, also known as the Parent Transaction ID.",
  TOKEN_ID: "Unique token id for transaction",
  EPOCH:
    "an epoch is a discrete period used to timestamp and organize events, transactions",
  MASTERNODE: "Master Node address",
  MASTERNODENAME: "Master Node Status MasterNode, ProposedNode or SlashedNode",
  SIGNNUMBER: "Sign Number for reward",
  REWARD_AMOUNT: "Reward Amount in XDC",
  REWARD_AGE: "Reward Age",
  BLOCK_HEIGHT:
    "It indicates the length of the blockchain, increases after the addition of the new block.",
  NO_OF_TRANSACTION:
    "Number of transactions associated with a particular block.",
  AMOUNT: "The number of tokens being transferred.",
  DATE: "Transaction creation date.",
  BLOCK_CREATION_TIME_STAMP: "Block creation timestamp.",
  TRANSACTION_CREATION_TIME_STAMP: "Transaction creation timestamp.",
  AGE: "Time when was the transaction created.",
  METHOD: "Function executed based on decoded input data.",
  BLOCK: "Block number associated with the transaction.",
  DIFFICULTY:
    "The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time.",
  GAS_USED: "The exact unit of gas that was used for the transactions.",
  FROM: "Sender’s account.",
  TO: "Receiver’s account.",
  SI_NO: "Serial Number.",
  SYMBOL: "Short name of the token.",
  NAME: "Name of the token.",
  TOKEN: "A virtual currency token or a denomination of a cryptocurrency.",
  TOKEN_TYPE: "Token type may include XRC20, XRC721 or XRC1155.",
  CONTRACT: "Smart Contract associated with the particular token.",
  HOLDER: "The total number of unique addresses that hold the token.",
  TOKEN_TOTAL_SUPPLY:
    "The number of coins that have been already created, minus any coins that have been burned.",
  TOKEN_TRANSFER_24_HOURS: "Transfer in 24 hours.",
  TOKEN_TRANSFER_3_DAYS: "Transfer in 3 days.",
  STATUS: "The token is either verified or unverified.",
  ACCOUNT_ADDRESS: "The unique address of a particular account.",
  ACCOUNT_TYPE: "Account type is either Account, Contract or Token.",
  ACCOUNT_BALANCE: "Balance held by a particular account.",
  WATCHLIST_ADDRESS: "User's wallet address.",
  WATCHLIST_DESCRIPTION:
    "The description provided by the user while creating the watchlist.",
  WATCHLIST_BALANCE: "The amount available in the wallet.",
  WATCHLIST_ADDED_ON: "Date on which address was added.",
  WATCHLIST_NOTIFICATION: "Notification priorities set by the users.",
  PRIVATE_NOTE: "A private note added to a transaction.",
  PRIVATE_NOTE_ADDED_ON: "Private note creation date.",
  TAG_ADDRESS: "Wallet addresses with tag names.",
  NAME_TAG: "The tag name that is given to an address.",
  TAG_ADDED_ON: "The date on which the address was tagged.",
  CONTRACT_ADDRESS: "The unique address of the contract.",
  TOKEN_NAME: "Name of the token associated with the contract.",
  CONTRACT_NAME: "Name of the Smart Contract.",
  TOKEN_YES_NO:
    "Whether the token is associated with the Smart Contract or not.",
  VALUE: "Amount to tokens being transferred.",
  GAS: "The average transaction fee associated with the transaction.",
  RANK: "Account’s rank sorted on the basis of Balance.",
  PERCENTAGE: "Percentage of holdings out of the total supply.",
  HOLDER_RANK: "Holder’s rank sorted on the basis of Quantity.",
  WALLET_ADDRESS: "Wallet address.",
  QUANTITY: "The amount available in the wallet.",
  EMAIL: "Email is required for sending the email notifications.",
  Total_Txn: "Total number of incoming and outgoing transaction.",
  Highest_Txn: "Highest amount send or received by the user.",
  Txn_Fee: "Total transaction fee paid by the user.",
  Tokens: "Number of XRC20 tokens available in the user's wallet.",
  AVERAGE_BALANCE: "Average daily balance.",
  PASSWORD:
    "Password must have at least 8 characters and contain the following: uppercase letters, lowercase letters, numbers, and symbols.",
  DOWNLOAD_CSV: "Select address by clicking on checkbox to export data.",
  RANGE_SELECTOR:
    "Dates can be selected within the date range of data available.",
  EXPORT_DISABLE: "Select address by clicking on checkbox to export data.",
  RANGE_SELECT:
    "Dates can be selected within the date range of data available.",
};

export const toolTipMessages = {
  AMOUNT: "The number of tokens being transferred.",
  INTERNALTRANSACTIONS: "Interernal transactions for transaction hash",
  REASON: "Transaction Failure Reason",
  hashid:
    "A transaction hash is a unique character identifier that is generated whenever the transaction is executed.",
  blocknumber: "Block number associated with the transaction.",
  timestamp: "The date and time at which a transaction is mined.",
  from: "The sending party of the transaction(could be from a contact address).",
  to: "The receiving party of the transaction(could be from a contact address).",
  value:
    "The value being transacted in XDC and fiat value. Note: You can click the fiat value(if available) to see historical value at the time of Transaction.",
  txnfee: "Amount paid for processing the transaction.",
  tokenTransferred: "Tokens transferred in the transaction.",
  transactionAction: "Highlighted event of the transaction.",
  interactedWithTo:
    "The receiving party of the transaction (could be a contract address).",
  gasprovided:
    "Maximum amount of gas provided for the transaction. For normal XDC transfers the value is 21,000. For contract this value is higher an bound by block gas limit.",
  gasprice:
    "Cost per unit of gas specified for the transaction, in XDC and Gwei. The higher the gas price the higher chance of getting included in a block.",
  gasused: "The exact unit of gas that was used for the transactions.",
  nounced:
    "Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be 11th transaction sent from the sender's address.",
  input: "Additional information that is required for the transaction.",
  transferToken:
    "The value being transacted in XDC and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction.",
  age: "Time when was the transaction created.",
  date: "Transaction creation date.",
  privatenote: `User can add a private note to the transaction. Private note is being saved in the local storage of the device.`,
};

export const decimalDivisionValue = {
  DECIMAL_DIVISON_VALUE: 1000000000000000000,
};

export const contractMethodTypes = {
  view: "view",
};

export const recentSearchTypeConstants = {
  TRANSACTION: "TRANSACTION",
  ACCOUNT: "WALLET",
  WALLET: "WALLET",
  BLOCK: "TOKEN",
  TOKEN: "TOKEN",
  CONTRACT: "CONTRACT",
};

export const authenticationProvider = {
  AUTH0: "AUTH0",
};

export const MethodFromByte = {
  a978501e: "transferFrom",
  "40c10f19": "mint",
  "8456cb59": "pause",
  "3f4ba83a": "resume",
  f1c064af: "ownership",
  "42966c68": "burn",
  e341eaa4: "sign",
  "3b1cd713": "approve",
  a9059cbb: "transfer",
  "4f18b3d5": "transferFrom",
  b992cdb8: "transferFrom",
  "811c34d3": "transferFrom",
  "8c5b0a87": "transferFrom",
  "857a6516": "transferFrom",
  "407d6189": "transferFrom",
  e4759493: "transferFrom",
  d6718db8: "_symbol",
  "49e65440 ": "setSymbol",
  a3895fff: "changeSymbol",
  efa74f1f: "getTokenBySymbol",
  "313ce567": "decimals",
  c112dfa3: "changeDecimals",
  "8c8885c8": "setDecimals",
  a44b47f7: "totalSUpply",
  "72dd529b": "totalSupply",
  "9707f708": "totalSupply",
  "94a08c69": "totalSupply",
  "5fd4b08a": "getName",
  "773bc712": "changeName",
  c47f0027: "setName",
  "5ac801fe": "setName",
  "48f97f9a": "setName",
  ba863fae: "balanceOf",
  "2980a5d3": "balanceOf",
  bd4b0b13: "balanceOfContract",
  "9a3174a0": "BalanceOf",
  "722713f7": "balanceOf",
  "3d64125b": "balanceOf",
  fa094954: "setTokenAllowance",
  c2f373d2: "allowance",
  "1777fc89": "setAllowance",
  "6aeac363": "_allowance",
  de242ff4: "allowance",
  "7040f3e5": "Allowance",
  "9c315aa8": "setAllowance",
};
