import moment from "moment";
import { eventConstants, cookiesConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";


let initialState = {
  activeTimezone: undefined,
};

const selectedTimezone = (state = initialState, action) => {
  switch (action.type) {
    case eventConstants.ACTIVE_TIMEZONE:
      sessionManager.setDataInLocalStorage(
        cookiesConstants.OBSERVER_TIMEZONE,
        action.data
      );
      return {
        ...state,
        activeTimezone: action.data,
      };
    default:
      return state;
  }
};

export default selectedTimezone;
