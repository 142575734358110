import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import "../../assets/styles/custom.css";

import SearchData from "../../services/search";
import Utility from "../../utility";

import { Row } from "simple-flexbox";
import { eventConstants, recentSearchTypeConstants } from "../../constants";
import { useDispatch } from "react-redux";
import SearchPopover from "../../modules/explorer/searchPopover";

const drawerWidth = 240;

const SearchBox = (props) => {
  const classes = props.classes;
  const dispatch = useDispatch();
  const SelectOptRef = React.useRef(null);
  const SearchDataRef = React.useRef(null);
  const currentTheme = props.currentTheme;
  const [errorMessage, setErrorMessage] = useState("");
  const [showSearchPopover, setShowSearchPopover] = useState(false);
  const [searchHover, setSearchHover] = useState(false);

  const BlockChainSearch = async (data) => {
    try {
      const [error, responseData] = await Utility.parseResponse(
        SearchData.searchData(data)
      );

      if (!responseData || responseData[0]?.token?.length == 0) {
        window.location.href = `/data-not-found?searchString=${data?.data}`;
      }

      if (responseData) {
        if (responseData[0].redirect === "block") {
          let blockurl = "/block-details/" + responseData[0].block.number;
          dispatch({
            type: eventConstants.ADD_TO_SEARCH_LIST,
            payload: {
              type: recentSearchTypeConstants.BLOCK,
              searchValue: data?.data || "",
              result:
                (responseData[0]?.block?.transactions?.length > 0 &&
                  responseData[0]?.block?.transactions.reduce(
                    (accumulator, trx) => accumulator + parseInt(trx.value),
                    [0]
                  )) ||
                0,
              redirectUrl: blockurl,
            },
          });
          window.location.href = blockurl;
        } else if (responseData[0].redirect === "contract") {
          let accounturl = "/address/" + responseData[0].account.address;
          dispatch({
            type: eventConstants.ADD_TO_SEARCH_LIST,
            payload: {
              type: recentSearchTypeConstants.CONTRACT,
              searchValue: responseData[0]?.account?.address || "",
              result: responseData[0]?.account?.balance || 0,
              redirectUrl: accounturl,
            },
          });
          window.location.href = accounturl;
        } else if (responseData[0].redirect === "account") {
          let accounturl = "/address/" + responseData[0].account.address;
          dispatch({
            type: eventConstants.ADD_TO_SEARCH_LIST,
            payload: {
              type: recentSearchTypeConstants.ACCOUNT,
              searchValue: responseData[0]?.account?.address || "",
              result: responseData[0]?.account?.balance || 0,
              redirectUrl: accounturl,
            },
          });
          window.location.href = accounturl;
        } else if (responseData[0].redirect === "transaction") {
          let transactionurl = "/txs/" + responseData[0].transaction.hash;
          dispatch({
            type: eventConstants.ADD_TO_SEARCH_LIST,
            payload: {
              type: recentSearchTypeConstants.TRANSACTION,
              searchValue: data?.data || "",
              result: responseData[0]?.transaction?.value || 0,
              redirectUrl: transactionurl,
            },
          });
          window.location.href = transactionurl;
        } else if (responseData[0].redirect === "token") {
          if (responseData[0]?.token.length == 1) {
            let tokenDataUrl = `/token-data/${
              responseData[0]?.token[0]?.address
            }/${
              responseData[0]?.token[0]?.symbol
                ? responseData[0]?.token[0]?.symbol
                : "NA"
            }`;

            dispatch({
              type: eventConstants.ADD_TO_SEARCH_LIST,
              payload: {
                type: recentSearchTypeConstants.TOKEN,
                searchValue: responseData[0]?.token[0]?.address || "",
                result: responseData[0]?.token[0]?.totalSupply || 0,
                redirectUrl: tokenDataUrl,
              },
            });
            window.location.href = tokenDataUrl;
          } else if (responseData[0]?.token.length > 1) {
            let tokenListUrl =
              "/tokens/" + responseData[0]?.token[0]?.tokenName;
            dispatch({
              type: eventConstants.ADD_TO_SEARCH_LIST,
              payload: {
                type: recentSearchTypeConstants.TOKEN,
                searchValue: responseData[0]?.token?.address || "",
                result: responseData[0]?.token?.totalSupply || 0,
                redirectUrl: tokenListUrl,
              },
            });
            window.location.href = tokenListUrl;
          } else {
          }
        } else {
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (event) => {
    let searchData = SearchDataRef.current.value;

    if (searchData.length == 0) {
      setErrorMessage("");
      return;
    }

    if (event.key === "Enter") {
      if (searchData.startsWith("0x") && searchData.length < 45) {
        searchData = searchData.replaceAll("0x", "xdc");
      }
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (format.test(searchData)) {
        window.location.href = `/data-not-found?searchString=${searchData}`;
      } else {
        var selectOptType = SelectOptRef.current?.value;

        let requestdata = {
          // filter: selectOptType,
          filter: "All filters",
          data: searchData,
        };
        BlockChainSearch(requestdata);
      }
    }
  };
  const handleSearchByButton = () => {
    let searchData = SearchDataRef.current.value;
    if (searchData.length == 0) {
      setErrorMessage("");
      return;
    }
    let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (searchData.startsWith("0x")) {
      searchData = searchData.replaceAll("0x", "xdc");
    }

    if (format.test(searchData)) {
      window.location.href = `/data-not-found?searchString=${searchData}`;
    } else {
      let selectOptType = SelectOptRef.current?.value;
      let requestdata = {
        // filter: selectOptType,
        filter: "All filters",
        data: searchData,
      };
      BlockChainSearch(requestdata);
    }
  };

  return (
    <>
      <div>
        <form
          method="post"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row alignItems="center" justifyContent="space-between">
            <div className={classes.searchImageinputContainer}>
              <img className={classes.searchIcon} src={"/images/Search.svg"} />
              <div className="search-responsive">
                <input
                  type="text"
                  onKeyUp={(event) => {
                    handleSearch(event);
                  }}
                  ref={SearchDataRef}
                  className={
                    currentTheme === "dark"
                      ? "main-input-td-dark"
                      : "main-input-td "
                  }
                  onFocus={(e) => {
                    setShowSearchPopover(true);
                  }}
                  onBlur={(e) => {
                    if (!searchHover) {
                      setShowSearchPopover(false);
                    }
                  }}
                  src={"/images/Search.png"}
                  placeholder="Search by Address / Txn Hash / Block / Token"
                />
              </div>
            </div>
            <div
              className={
                props.theme === "dark"
                  ? "white-space-no-wrap bg-192a59 br-4 p-wallet-search cursor-pointer m-r-7-tab"
                  : "white-space-no-wrap border-d2deff bg-eaf0ff br-4 p-wallet-search cursor-pointer m-r-7-tab"
              }
              onClick={handleSearchByButton}
            >
              <span className="color-4878ff fs-14 fw-500">Search</span>
            </div>
          </Row>
        </form>
      </div>
      {showSearchPopover && (
        <SearchPopover
          shortenValue={props.shortenValue}
          searchString={(SearchDataRef.current.value || "").trim()}
          theme={props.theme}
          mouseHovering={(mouseHover) => {
            setSearchHover(mouseHover);
          }}
        />
      )}
    </>
  );
};
export default SearchBox;
