import { eventConstants, cookiesConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

let initialState = {
  accountTagList: [],
};
const updateAccountTagList = (state = initialState, action) => {
  switch (action.type) {
    case eventConstants.ADD_ACCOUNT_TAGS:
      sessionManager.setDataInLocalStorage(
        cookiesConstants.ACCOUNT_TAGS,
        action.payload
      );
      return {
        ...state,
        accountTagList: action.payload,
      };
    default:
      return state;
  }
};

export default updateAccountTagList;
